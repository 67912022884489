/* Containers */

.form_field_container {
    @apply mx-2 w-full flex-1 mt-8;
}

.form_currency_symbol_container {
    @apply pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3;
}


/* Form Styles & Field Descriptive Styles */

.form_label {
    @apply block mb-2 text-sm font-medium text-gray-900 dark:text-white;
}

.form_step_title {
    @apply text-3xl font-medium leading-tight;
}

.form_step_description {
    @apply mt-3 font-normal leading-7 text-gray-500 pt-2 mb-2;
}

.form_field_description {
    @apply text-sm mb-2 text-gray-500 dark:text-gray-400;
}

/* Input Styles */

.form_text_area {
    @apply block mt-3 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.form_input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 mt-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.form_valid_input {
    @apply bg-green-50 border border-green-500 text-green-900 placeholder-green-700 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 mt-3 dark:bg-green-100 dark:border-green-400;
}

.form_invalid_input {
    @apply bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400;
}

.form_monetary_input {
    @apply block w-full rounded-md border-gray-300 pl-7 pr-12 mt-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm;
}

.form_currency {
    @apply h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm;
}

.form_select_input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.form_currency_symbol {
    @apply text-gray-500 sm:text-sm;
}