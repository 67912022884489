
.wrapperClassName {
    padding: 0.4rem;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.editorClassName {
    background-color: white;
    padding: 0.5rem;
}

.toolbarClassName {
    border: 0px;
}