
.success_container {
    @apply mb-4 rounded-lg bg-green-100 py-5 px-6 text-base;
}


.success_heading {
    @apply mb-3 text-4xl font-medium leading-tight text-green-900;
}


/* Confirmation Code Styles */


.confirmation_code_heading {
    @apply mb-5 mt-5 text-xl font-bold text-gray-900 dark:text-white md:text-5xl lg:text-6xl;
}

.confirmation_code_style {
    @apply text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400
}
