

.step.active {
    @apply flex items-center text-blue-600 dark:text-blue-500 space-x-2.5;
}

.step.inactive{
    @apply flex items-center text-gray-500 dark:text-gray-400 space-x-2.5;
}

.step.complete{
    @apply flex items-center text-green-600 dark:text-green-500 space-x-2.5;
}

.mark.active {
    @apply flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500;
}

.mark.passive {
    @apply flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400
}

.mark.complete {
    @apply flex items-center justify-center w-8 h-8 bg-green-500 text-white border border-green-600 rounded-full shrink-0 dark:border-green-500;
}

.next_step_button {
    @apply text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-500 dark:hover:bg-yellow-600 dark:focus:ring-yellow-700;
}

.previous_step_button {
    @apply text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700;
}

.publish_button {
    @apply text-white bg-green-500 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-orange-700 dark:focus:ring-green-800
}